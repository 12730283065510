import { lazy, ReactNode } from "react";

export interface Route {
  path: string;
  /**
   * Screen component goes here
   */
  render: ReactNode;
  name: string;
  exact?: boolean;
}

//-----[Lazy Imports Screens]-----
const Map = lazy(() => import("screens/Map"));
const Raob = lazy(() => import("screens/Raob"));
const Wbgt = lazy(() => import("screens/Wbgt"));
const Hi = lazy(() => import("screens/Hi"));

//-----[Routes]-----
const routes: Route[] = [
  { name: "Map", path: "/", exact: true, render: <Map /> },
  { name: "Raob", path: "/raob", exact: true, render: <Raob /> },
  { name: "WBGT", path: "/WBGT", exact: true, render: <Wbgt /> },
  { name: "Hi", path: "/hi", exact: true, render: <Hi /> },
];

export default routes;
